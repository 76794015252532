import enVeeValidation from "@/locales/enVeeValidation";
import neVeeValidation from "@/locales/neVeeValidation";
import VeeValidate, { Configuration, Rule } from "vee-validate";
import Vue from "vue";

import { JWTStore } from "./store/modules";
Vue.use(VeeValidate);

// Configuration for VeeValidate to prevent collision with elementUI validation
const config: Configuration = {
  fieldsBagName: "vFields",
  errorBagName: "vErrors"
};

VeeValidate.configure(config);
VeeValidate.Validator.extend("checkUserExists", {
  /** message is taken from traslation files */
  validate: async (value: any, args: any[]) => {
    if (value) {
      await JWTStore.userexists(value);
      return !JWTStore.UserExists;
    }
    return false;
  }
} as Rule);

VeeValidate.Validator.extend("imageFile", {
  /** message is taken from traslation files */
  validate: async (file: any, args: any[]) => {
    if (typeof file == "string") {
      return /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(file);
    } else if (file instanceof File) {
      return file.type.includes("image");
    }
    return false;
  }
} as Rule);

VeeValidate.Validator.extend("fileSize", {
  /** message is taken from traslation files */
  validate: async (file: any, args: any[]) => {
    if (typeof file == "string") {
      return true;
    } else if (file instanceof File) {
      var size = args[0];

      if (isNaN(size)) {
        return false;
      }
      var nSize = Number(size) * 1024;
      return file.size <= nSize;
    }
    return false;
  }
} as Rule);

// Use this instead of vue i18n integration as that has issue with file size validator
VeeValidate.Validator.localize({ ne: neVeeValidation, en: enVeeValidation });
