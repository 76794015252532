export const fileUploadUtil = (formdata: FormData, fileInputObject: {}) => {
  for (let objectKey in fileInputObject as any) {
    for (let file of (fileInputObject as any)[objectKey]) {
      formdata.append(objectKey, file);
    }
  }
};

export function getTodayDate() {
  const date = new Date();

  let currentDay = String(date.getDate()).padStart(2, "0");

  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

  let currentYear = date.getFullYear();

  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  return currentDate;
}

export function formatNumber(num: string | number) {
  let formattedNum = Number(num).toLocaleString("en-NE", {
    maximumFractionDigits: 2
  });
  return formattedNum;
}

export function syncSleep(ms: number) {
  const start = new Date().getTime();
  for (let i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > ms) {
      break;
    }
  }
}

export async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms));
}

export function pathReplacer(
  action: string,
  valueList: { name: string; value: number | string }[]
): string {
  for (const value of valueList) {
    action = action.replace(value.name, String(value.value));
  }
  return action;
}
export function getNestedFormData(
  formData: FormData,
  // eslint-disable-next-line
  data: any,
  prekey: string | null = null
) {
  for (const key of Object.keys(data)) {
    let dataKey = key;
    if (prekey) {
      dataKey = `${prekey}[${key}]`;
    } else if (Array.isArray(data)) {
      dataKey = `[${dataKey}]`;
    }
    if (typeof data[key] == "object") {
      if (data[key] instanceof File) {
        formData.append(dataKey, data[key]);
      } else if (data[key] === null) {
        // pass
      } else {
        formData = getNestedFormData(formData, data[key], dataKey);
      }
    } else {
      formData.append(dataKey, data[key]);
    }
  }
  return formData;
}

export function removeFilesForPatch(fileList: { property: any; fieldName: any }[]) {
  for (let file of fileList) {
    const property = file.property;
    const fieldName = file.fieldName;
    let field = property[fieldName];
    if (field === undefined || field === null || typeof field === "string") {
      delete property[fieldName];
    }
  }
}
